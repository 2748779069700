/* Contact Us Section */
.contact-us-section {
  position: relative;
  overflow: hidden;
  text-align: center;
  color: #ffffff;
  box-shadow: 0 -2px 12px #68b1b8, 0 15px 30px rgba(0, 0, 0, 0.2); /* Default shadow */

}

.contact-us-section .contact-container {
  position: relative;
  z-index: 1;
  background-color: #000000e7;
  padding: 2rem;
  border-radius: 8px;
  max-width: 725px;
  

}

.contact-us-section h2 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

.contact-us-section p {
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: left;
}

.contact-us-section i {
  margin-right: 0.5rem;
}

.contact-us-section a {
  color: #ffffff;
  text-decoration: none;
  transition: color 0.3s ease, transform 0.3s ease;
  position: relative;
  font-weight: bold;
  margin-left: 0.5rem;
}

.contact-us-section p a::after {
  content: '';
  display: block;
  width: 0;
  height: 2px;
  background: #68b1b8;
  transition: width 0.3s ease;
  position: absolute;
  left: 0;
  bottom: -3px;
  margin-left: -0.5rem;
}

.contact-us-section a:hover::after {
  width: 100%;
  margin-left: 0;
}

.contact-us-section a:hover {
  color: #68b1b8;
}

/* Responsive design for contact section */
@media (max-width: 768px) {
  
  .contact-us-section h2 {
    font-size: 2rem;
  }

  .contact-us-section p {
    font-size: 1rem;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
  }

  .contact-us-section a {
    margin-left: 0;
  }

  .contact-us-section p a::after {
    margin-left: 0;
  }

}
