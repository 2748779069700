/* src/components/common/Navbar.css */
.navbar {
  display: flex;
  justify-content: center; /* Center the navbar content */
  align-items: center;
  background-color: rgba(0, 0, 0, 0.884);
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  box-shadow: 0 2px 5px #68b1b8;; /* Adding a shadow effect */

}

.navbar-wrapper {
  width: 100%; /* Account for 10rem margin on each side */
  margin: 0 auto; /* Center the navbar horizontally */
  transition: background-color 0.3s ease;
  height: 140px;
}


.navbar-content {
  display: flex;
  align-items: center;
  width: 100%;
  margin: 2rem auto; /* Center the content within the navbar */
  position: relative;

}

.logo {
  position: absolute;
  left: 15rem; /* Adjust the left margin as needed */
  flex: 0 0 auto; /* Prevent flex growing */
}

.logo img {
  width: 100px;
  height: auto;

}

.quote-container {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

.nav-links {
  list-style: none;
  display: flex;
  align-items: center; /* Ensures vertical centering */
  justify-content: center; /* Ensures links are evenly spaced horizontally */
  margin-left: auto;
  margin-right: 8rem;
  gap: 5rem;
}


.nav-links a {
  text-decoration: none;
  color: #ffffff;
  font-weight: bold;
  transition: color 0.3s ease;
  font-size: 1.25rem;
  vertical-align: middle;
}

.nav-links a:hover {
  color: #68b1b8;
}

.nav-links a::after {
  content: '';
  display: block;
  width: 0;
  height: 2px;
  background: #68b1b8;
  transition: width 0.3s ease;
}

.nav-links a:hover::after {
  width: 100%;
}

.menu-icon {
  display: none;
  font-size: 2rem;
  cursor: pointer;
}

.nav-links li {
  position: relative;
}

.dropdown-menu {
  display: none;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.938);
  top: 100%;
  left: 0;
  list-style: none;
  padding: 0;
  margin: 0;
  z-index: 1000;
  border-radius: 8px;
  width: auto; /* Auto width based on content */
  text-align: left;
  padding: 0.5rem 0;
  font-size: 1.25rem;
}

.dropdown-menu.show {
  display: block; /* Show the dropdown when the 'show' class is added */
}

.dropdown-menu li a {
  padding: 0.5rem 0.5rem; /* Adjust padding as needed */
  display: block;
  color: #ffffff;
  text-decoration: none;
  white-space: nowrap; /* Prevent text from wrapping */
  position: relative; /* Required for ::after */
}

.dropdown-menu li a::after {
  content: '';
  display: block;
  width: 0;
  height: 2px;
  background: #68b1b8;
  transition: width 0.3s ease;
  position: absolute;
  bottom: 0; /* Position the underline at the bottom */
  left: 0;
}

.dropdown-menu li a:hover::after {
  width: 100%;
}

/* Dropdown button styles */
.dropdown-menu-arrow {
  background-color: transparent;
  border-color: transparent;
  text-decoration: none;
  color: #ffffff;
  font-weight: bold;
  transition: color 0.3s ease;
  font-size: 1.25rem;
}

.dropdown-menu-arrow:hover {
  color: #68b1b8; /* Highlight text same as underline color */
}

.dropdown-menu-arrow::after {
  content: '';
  display: block;
  width: 0;
  height: 2px;
  background: #68b1b8;
  transition: width 0.3s ease;

}

.dropdown-menu-arrow:hover::after {
  width: 100%; /* Underline matches text width */
}

/* Rotate arrow on dropdown open */
.dropdown-arrow {
  margin-left: 0.5rem;
  transition: transform 0.3s ease;
}

.dropdown-arrow.rotate {
  transform: rotate(180deg); /* Rotate the arrow */
}


@media (max-width: 1330px) {
  .nav-links { 
    margin-right: 5rem; /* Center the content within the navbar */
  }

  .logo {
    left: 5rem;
  }
}

@media (max-width: 970px) {
  .menu-icon {
    display: block;
    position: absolute;
    color: #ffffff;
    right: 2rem;
    padding-top: 10px;
    padding-bottom: 10px;
  }

  .logo {
    left: 5rem;
  }

  .logo img {
    width: 80px;
    height: auto;
  }

  .navbar {
    background-color: rgba(0, 0, 0, 0.884);
    width: 100%; /* Full width on smaller screens */
  }

  .navbar-wrapper {
    width: 100%; /* Full width on smaller screens */
    margin: 0; /* Remove margins */
    height: 80px;
  }

  .navbar-content {
    padding: 10px;
  }

  .nav-links {
    position: absolute;
    top: 35px;
    right: -3rem;
    flex-direction: column;
    background-color: rgba(0, 0, 0, 0.932);
    border-radius: 8px;
    padding: 0rem;
    display: none;
    z-index: 1000;
    gap: 0;
 
  }

  .nav-links.nav-active {
    display: flex;
  }

  .nav-links li {
    width: 100%;
    text-align: left;
    margin: 0;

  }

  .nav-links a {
    padding: 0.75rem 0.5rem;
    display: block;
    font-size: 1rem;

  }

  .dropdown-menu-arrow {
    font-size: 1rem;
    padding: 0.75rem 0.5rem;
  }
}
