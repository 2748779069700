/* Modal.css */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal {
  background-color: transparent;
  padding: 2rem;
  border-radius: 8px;
  position: relative;
  width: auto;
  max-width: 800px;
  max-height: 80%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: auto;
}

.modal-content {
  flex-grow: 1;
}

.close-button {
  background-color: #68b1b8;
  color: #fff;
  padding: 0.5rem 1rem;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
  margin-top: 0rem;
  width: 100%; /* Ensure button spans the width of the modal */
  text-align: center; /* Center the text inside the button */
}

.close-button:hover {
  background-color: #fff;
  color: #68b1b8;

}

