/* src/components/common/Footer.css */
.footer {
  background-color: #141414;
  color: #fff;
  text-align: center;
  position: relative; /* Change to fixed */
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 999; /* Ensure it stays above other content */
  box-shadow: 0 -2px 5px #68b1b8;; /* Adding a shadow effect */
}

.footer-content {
  max-width: 1500px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
}

.footer-links {
  display: flex;
  gap: 2rem;
  margin-bottom: 1rem;
}

.footer-links a {
  color: #fff;
  text-decoration: none;
  font-weight: bold;
  transition: color 0.3s ease;
  position: relative; /* Add this to position the ::after element */
}

.footer-links a:hover {
  color: #68b1b8;
}

.footer-links a::after {
  content: '';
  display: block;
  width: 0;
  height: 2px;
  background: #68b1b8;
  transition: width 0.3s ease;
  position: absolute;
  left: 0;
  bottom: -2px; /* Adjust this value if needed */
}

.footer-links a:hover::after {
  width: 100%;
}

.footer-copyright {
  font-size: 1rem;
  margin-bottom: 1rem;
}

.disclaimer a {
  color: #fff;
  text-decoration: none;
  transition: color 0.3s ease;
  position: relative; /* Add this to position the ::after element */
}

.disclaimer a:hover {
  color: #68b1b8;
}

.disclaimer a::after {
  content: '';
  display: block;
  width: 0;
  height: 2px;
  background: #68b1b8;
  transition: width 0.3s ease;
  position: absolute;
  left: 0;
  bottom: -2px; /* Adjust this value if needed */
}

.disclaimer a:hover::after {
  width: 100%;
}
